import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/auth-callback": [5],
		"/gem": [6],
		"/organization/[orgId]/location/[locId]": [7,[2],[3]],
		"/organization/[orgId]/location/[locId]/86": [8,[2],[3]],
		"/organization/[orgId]/location/[locId]/cart-rule/add": [9,[2],[3]],
		"/organization/[orgId]/location/[locId]/catalogs": [11,[2],[3]],
		"/organization/[orgId]/location/[locId]/catalog": [10,[2],[3]],
		"/organization/[orgId]/location/[locId]/concessionaire": [12,[2],[3]],
		"/organization/[orgId]/location/[locId]/connectors": [13,[2],[3]],
		"/organization/[orgId]/location/[locId]/connectors/[id]": [14,[2],[3]],
		"/organization/[orgId]/location/[locId]/connectors/[id]/snapshot-groups": [15,[2],[3]],
		"/organization/[orgId]/location/[locId]/connectors/[id]/snapshot-groups/[snapshotGroupId]": [16,[2],[3]],
		"/organization/[orgId]/location/[locId]/dashboard": [17,[2],[3]],
		"/organization/[orgId]/location/[locId]/deployments": [18,[2],[3]],
		"/organization/[orgId]/location/[locId]/deployments/create": [20,[2],[3]],
		"/organization/[orgId]/location/[locId]/deployments/preview/[id]": [21,[2],[3]],
		"/organization/[orgId]/location/[locId]/deployments/[id]": [19,[2],[3]],
		"/organization/[orgId]/location/[locId]/diagnostic": [22,[2],[3]],
		"/organization/[orgId]/location/[locId]/discounts": [23,[2],[3]],
		"/organization/[orgId]/location/[locId]/discounts/add": [24,[2],[3]],
		"/organization/[orgId]/location/[locId]/discounts/edit/[id]": [25,[2],[3]],
		"/organization/[orgId]/location/[locId]/edit-nge-location/[id]": [26,[2],[3]],
		"/organization/[orgId]/location/[locId]/frequent-customer": [27,[2],[3]],
		"/organization/[orgId]/location/[locId]/gem": [28,[2],[3]],
		"/organization/[orgId]/location/[locId]/globalSettings/cep": [29,[2],[3]],
		"/organization/[orgId]/location/[locId]/gou/dashboards": [30,[2],[3]],
		"/organization/[orgId]/location/[locId]/gou/groups": [31,[2],[3]],
		"/organization/[orgId]/location/[locId]/gou/groups/[id]": [32,[2],[3]],
		"/organization/[orgId]/location/[locId]/gou/onboardingwizard": [33,[2],[3]],
		"/organization/[orgId]/location/[locId]/gou/organizations": [34,[2],[3]],
		"/organization/[orgId]/location/[locId]/gou/organizations/admin-configuration/[id]": [36,[2],[3]],
		"/organization/[orgId]/location/[locId]/gou/organizations/userGroup/[orgId]": [37,[2],[3]],
		"/organization/[orgId]/location/[locId]/gou/organizations/userGroup/[orgId]/[ugid]": [38,[2],[3]],
		"/organization/[orgId]/location/[locId]/gou/organizations/users/[orgId]": [39,[2],[3]],
		"/organization/[orgId]/location/[locId]/gou/organizations/[pid]/[id]/[type]": [35,[2],[3]],
		"/organization/[orgId]/location/[locId]/gou/roles": [40,[2],[3]],
		"/organization/[orgId]/location/[locId]/gou/users": [41,[2],[3]],
		"/organization/[orgId]/location/[locId]/gsh/glance": [42,[2],[3]],
		"/organization/[orgId]/location/[locId]/gsh/platform-glance": [43,[2],[3]],
		"/organization/[orgId]/location/[locId]/insights/aborted-orders-analytics": [44,[2],[3]],
		"/organization/[orgId]/location/[locId]/insights/custom-reports-analytics": [45,[2],[3]],
		"/organization/[orgId]/location/[locId]/insights/customer-surveys": [46,[2],[3]],
		"/organization/[orgId]/location/[locId]/insights/discounts-analytics": [47,[2],[3]],
		"/organization/[orgId]/location/[locId]/insights/glance": [48,[2],[3]],
		"/organization/[orgId]/location/[locId]/insights/order-timing-analytics": [49,[2],[3]],
		"/organization/[orgId]/location/[locId]/insights/product-analytics": [50,[2],[3]],
		"/organization/[orgId]/location/[locId]/insights/sales-analytics": [51,[2],[3]],
		"/organization/[orgId]/location/[locId]/insights/store-performance-analytics": [52,[2],[3]],
		"/organization/[orgId]/location/[locId]/insights/system-health-analytics": [53,[2],[3]],
		"/organization/[orgId]/location/[locId]/insights/upsell-analytics": [54,[2],[3]],
		"/organization/[orgId]/location/[locId]/insights/upsell-summary": [55,[2],[3]],
		"/organization/[orgId]/location/[locId]/insights/user-behavior-analytics": [56,[2],[3]],
		"/organization/[orgId]/location/[locId]/insights/view-report/[id]": [57,[2],[3]],
		"/organization/[orgId]/location/[locId]/item-upsell": [58,[2],[3]],
		"/organization/[orgId]/location/[locId]/item-upsell/add": [59,[2],[3]],
		"/organization/[orgId]/location/[locId]/kiosk-registration": [62,[2],[3]],
		"/organization/[orgId]/location/[locId]/kioskdatasets": [63,[2],[3]],
		"/organization/[orgId]/location/[locId]/kioskdatasets/[id]": [64,[2],[3]],
		"/organization/[orgId]/location/[locId]/kiosk/orders": [60,[2],[3]],
		"/organization/[orgId]/location/[locId]/kiosk/surveys": [61,[2],[3]],
		"/organization/[orgId]/location/[locId]/locations": [65,[2],[3]],
		"/organization/[orgId]/location/[locId]/loyalty": [66,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu-manager": [74,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu-manager/catalog": [75,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu-manager/category/add": [77,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu-manager/category/[id]": [76,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu-manager/combo-component/[id]": [79,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu-manager/combo-families/add": [81,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu-manager/combo-families/[id]": [80,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu-manager/combo/[id]": [78,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu-manager/item/add": [83,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu-manager/item/[id]": [82,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu-manager/modifier-code/[id]": [85,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu-manager/modifier-group/[id]": [86,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu-manager/modifier/[id]": [84,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu-override": [87,[2],[3]],
		"/organization/[orgId]/location/[locId]/menudatasets": [88,[2],[3]],
		"/organization/[orgId]/location/[locId]/menudatasets/[id]": [89,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu/category/add": [67,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu/category/edit/[id]": [68,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu/item/[id]": [69,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu/modifier-group/add": [71,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu/modifier-group/edit/[id]": [72,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu/modifier/[id]": [70,[2],[3]],
		"/organization/[orgId]/location/[locId]/menu/sync": [73,[2],[3]],
		"/organization/[orgId]/location/[locId]/order-upsell": [90,[2],[3]],
		"/organization/[orgId]/location/[locId]/order-upsell/add": [91,[2],[3]],
		"/organization/[orgId]/location/[locId]/payment": [92,[2],[3]],
		"/organization/[orgId]/location/[locId]/pos/connector": [93,[2],[3]],
		"/organization/[orgId]/location/[locId]/pos/connector/[id]": [94,[2],[3]],
		"/organization/[orgId]/location/[locId]/pos/connector/[id]/snapshot-groups": [95,[2],[3]],
		"/organization/[orgId]/location/[locId]/pos/connector/[id]/snapshot-groups/[snapshotGroupId]": [96,[2],[3]],
		"/organization/[orgId]/location/[locId]/pos/integration": [97,[2],[3]],
		"/organization/[orgId]/location/[locId]/pos/integration/[id]": [98,[2],[3]],
		"/organization/[orgId]/location/[locId]/pos/integration/[id]/[connectorId]": [99,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/abtesting": [100,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/appearance/colors": [101,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/appearance/fonts": [102,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/appearance/images": [103,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/appearance/kiosk-appearance": [104,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/appearance/textoverrides": [105,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/customization/business-hours": [106,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/customization/disclaimer": [107,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/customization/features": [108,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/customization/legal-copy": [109,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/customization/locale": [110,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/customization/message-guards": [111,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/customization/ordering-options": [112,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/customization/preorder": [113,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/customization/receipts": [114,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/customization/timeouts": [115,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/customization/tips": [116,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/kiosks": [117,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/kiosks/[id]": [118,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/kitchen-printers": [119,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/kitchen-printers/add": [121,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/kitchen-printers/[id]/edit": [120,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/location/access": [122,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/location/info": [123,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/location/setup-checklist": [124,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/location/staff": [125,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/location/staff/add": [126,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/loyalty": [127,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/order-types": [128,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/payment": [129,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/pos": [130,[2],[3]],
		"/organization/[orgId]/location/[locId]/settings/taxes": [131,[2],[3]],
		"/organization/[orgId]/location/[locId]/template/notification-template": [132,[2],[3]],
		"/organization/[orgId]/location/[locId]/template/notification-template/[id]": [133,[2],[3]],
		"/organization/[orgId]/location/[locId]/upsells": [134,[2],[3]],
		"/product-mix": [135],
		"/profile": [136],
		"/sales": [137],
		"/select-organization": [138],
		"/speed-of-service": [139],
		"/welcome": [140]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';